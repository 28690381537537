var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Analytics",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Toolbar', [_c('div', [_c('Datepicker', {
    attrs: {
      "startDate": _vm.selectedFrom,
      "monthPicker": ""
    },
    on: {
      "onChange": _vm.handleDateChange
    }
  }), _c('Dropdown', {
    attrs: {
      "items": _vm.dropdownValues,
      "selected": _vm.selectedValue && _vm.selectedValue.value,
      "variant": "single-column",
      "hasRemoveIcon": false,
      "placeholder": "Kategorie auswählen"
    },
    on: {
      "onItemSelect": _vm.handleDropdownSelect
    }
  }), _c('Button', {
    attrs: {
      "size": "small",
      "isLoading": _vm.isFetchingData
    },
    on: {
      "onClick": _vm.handleQuery
    }
  }, [_vm._v(" Anzeigen ")])], 1), _c('div', [_c('Download', {
    attrs: {
      "title": "Download CSV",
      "type": "csv"
    },
    on: {
      "onDownload": function onDownload($event) {
        return _vm.handleDownload('csv');
      }
    }
  })], 1)]), _c('AnalyticsTable', {
    attrs: {
      "shifts": _vm.shifts,
      "startAt": _vm.startAt,
      "endAt": _vm.endAt,
      "option": _vm.selectedValue
    },
    on: {
      "onCVSChange": _vm.handleCSVChange,
      "onRowHover": _vm.handleRowHover,
      "onDriverClick": _vm.handleDriverClick
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }